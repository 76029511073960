import { createMedia } from '@artsy/fresnel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Ref, Segment } from 'semantic-ui-react';
import FAQ from '../component/FAQ';
import Footer from './Footer';
import { DesktopHero, MobileHero } from './Hero';
import Info from './Info';
import DesktopNavbar from './desktop/Navbar';
import MobileNavbar from './mobile/Navbar';
import usePageState from '../hooks/usePageState';
import { PopupOverlay } from '../component/PopupOverlay';
import Press from '../component/Press';
import GlobalContext from '../context/GlobalContext';
import { NewsletterModal } from '../modal/NewsletterModal';

let newsletterTimeout = parseInt(
  process.env.REACT_APP_NEWSLETTER_TIMEOUT || '0',
);
if (isNaN(newsletterTimeout)) {
  newsletterTimeout = 30000;
}

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
    desktop: 1366,
  },
});

const EmailContainer: React.FC<{}> = () => {
  const [displayNewsletterModal, setDisplayNewsletterModal] = useState(false);
  const globalContext = useContext(GlobalContext);

  const {
    hasNewsletterBeenDisplayed,
    onNewsletterDisplayed,
    onNewsletterHidden,
  } = globalContext;

  useEffect(() => {
    if (newsletterTimeout && !hasNewsletterBeenDisplayed) {
      setTimeout(() => {
        setDisplayNewsletterModal(true);
        onNewsletterDisplayed();
      }, newsletterTimeout);
    }

    return () => {
      setDisplayNewsletterModal(false);
      onNewsletterHidden();
    };
  }, []);

  return (
    <NewsletterModal
      open={displayNewsletterModal}
      onClose={() => {
        setDisplayNewsletterModal(false);
        onNewsletterHidden();
      }}
    />
  );
};

const MobileSiteContainer: React.FC<{}> = () => {
  const contextRef = useRef<HTMLElement>(null);
  const [showFAQ, openFAQ, closeFAQ] = usePageState('faq', true);
  const [showNews, openNews, closeNews] = usePageState('news', true);

  return (
    <Media lessThan="computer">
      <Ref innerRef={contextRef}>
        {/* Ref only takes one child, so we need to add empty Fragment tag then put children inside */}
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              height: '50%',
              width: '100%',
              backgroundColor: !(showFAQ || showNews) ? '#0032b0' : '#FFF',
            }}
          ></div>
          <div
            style={{
              position: 'fixed',
              right: 0,
              bottom: 0,
              height: '50%',
              width: '100%',
              backgroundColor: !(showFAQ || showNews) ? '#abe810' : '#FFF',
            }}
          ></div>
          <MobileNavbar
            handleFAQOpen={openFAQ}
            handleFAQClose={closeFAQ}
            handleNewsOpen={openNews}
            handleNewsClose={closeNews}
          >
            <Segment
              basic
              style={{
                padding: 0,
                margin: 0,
                borderRadius: 0,
              }}
            >
              <EmailContainer />
              {(() => {
                if (showFAQ) {
                  return (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: '6em',
                      }}
                    >
                      <FAQ />
                    </div>
                  );
                }
                if (showNews) {
                  return (
                    <div
                      style={{
                        textAlign: 'left',
                        marginTop: '6em',
                      }}
                    >
                      <Press />
                    </div>
                  );
                }
                return (
                  <>
                    <MobileHero />
                    <Info />
                    <Footer />
                  </>
                );
              })()}
            </Segment>
          </MobileNavbar>
        </>
      </Ref>
    </Media>
  );
};

const DesktopSiteContainer: React.FC<{}> = () => {
  const [heroHeight, setHeroHeight] = useState(0);
  const heroRef = useRef<HTMLDivElement>(null);
  const [showFAQ, openFAQ, closeFAQ] = usePageState('faq');
  const [showNews, openNews, closeNews] = usePageState('news');

  useEffect(() => {
    setHeroHeight(heroRef.current?.clientHeight || 0);
  }, [setHeroHeight]);

  return (
    <Media greaterThanOrEqual="computer">
      <DesktopNavbar
        heroHeight={heroHeight}
        handleFAQOpen={openFAQ}
        handleNewsOpen={openNews}
      />
      <MediaContextProvider>
        <Media at="computer">
          <Ref innerRef={heroRef}>
            <MobileHero />
          </Ref>
        </Media>
        <Media greaterThanOrEqual="desktop">
          <Ref innerRef={heroRef}>
            <DesktopHero />
          </Ref>
        </Media>
      </MediaContextProvider>
      <PopupOverlay
        toggled={showFAQ || showNews}
        handleClose={showFAQ ? closeFAQ : closeNews}
        buttonStyle={{
          backgroundColor: '#ce0f46',
          color: '#abe810',
          padding: '0.5em 0.6em',
          fontFamily: 'Poppins, sans-serif',
          borderRadius: '4em',
        }}
      >
        {showFAQ ? <FAQ /> : <Press />}
      </PopupOverlay>
      <EmailContainer />
      <Info />
      <Footer />
    </Media>
  );
};

const SiteContainer: React.FC<{}> = (props) => {
  return (
    <MediaContextProvider>
      <DesktopSiteContainer {...props} />
      <MobileSiteContainer {...props} />
    </MediaContextProvider>
  );
};

export default SiteContainer;
