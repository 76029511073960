import React from 'react';
import { Container, Header, Image, Ref } from 'semantic-ui-react';
import { PopOpenLink } from './PopOpenLink';

import TPLiveLogo from '../assets/TP Live Logo.svg';
import { usePopupRef } from '../hooks/usePopupRef';

const Press: React.FC = () => {
  const headerRef = usePopupRef(true);

  return (
    <Container
      style={{ marginTop: '3rem', paddingBottom: '5rem', marginBottom: '3rem' }}
      text={true}
      role="dialog"
    >
      <Image
        style={{
          margin: 'auto',
        }}
        size="small"
        src={TPLiveLogo}
        alt="TimePlay Live logo"
      />

      <div
        style={{
          textAlign: 'center',
          margin: '1.5em 1em',
        }}
      >
        <Ref innerRef={headerRef}>
          <Header
            tabIndex={-1}
            style={{
              outline: 'none',
            }}
          >
            TimePlay launches new at-home interactive gaming platform
          </Header>
        </Ref>
        <p>
          <b>Winner of inaugural event to take home 10,000 SCENE Points</b>
        </p>
      </div>

      <p>
        TORONTO, March 23, 2021 &ndash; TimePlay has relaunched with TimePlay
        Live, an at-home casual gaming experience. The play-from-your-couch
        battle royale knowledge show amps up the fun with three live games per
        week where you will compete against players for a place on the
        leaderboard for prizing and bragging rights. To celebrate the relaunch
        of the experience, TimePlay will showcase its new platform at an
        inaugural event on April 22, 2021 at 8:30 pm ET. The top-scoring
        contestant at the launch event will walk away with a special, inaugural
        grand prize of 10,000 SCENE points.
      </p>

      <p>
        The TimePlay app transforms your phone into a rich game controller to
        interact with the second screen, which in this case is your browser or
        big screen TV that is activated from{' '}
        <PopOpenLink href="http://www.timeplay.tv">www.timeplay.tv</PopOpenLink>
        . The fun begins every Sunday, Tuesday and Thursday at 8:30pm ET.
        Contestants will be placed into virtual rooms or pods to compete against
        smaller groups increasing their chances of winning in each pod.
      </p>

      <p>
        <i>
          “TimePlay Live is busting out of the four walls of the cinema and
          brings an immersive experience into your own interactive living room,”
        </i>{' '}
        said Oren Rosen, CEO of TimePlay.{' '}
        <i>
          “We can’t wait for Canadians to experience the fresh new take on the
          game show they know and love.”
        </i>
      </p>

      <p>
        The first-of-its-kind cloud-based offering boasts a new look and feel
        and tracks your ranking in a national leaderboard as you compete with
        movie fans coast to coast. While previously centred on movie trivia, the
        new TimePlay Live has broadened into additional categories including TV,
        Music, Pop Culture and Games, giving lots more chances to show what you
        know.
      </p>

      <p>
        Users will walk away with prizes they know and love, including 500 SCENE
        Points being awarded to the winner of each pod. In addition to the top
        prizes available to be won, users will be awarded SCENE Points simply
        for playing the game.
      </p>

      <p>
        The casual interactive gaming company had a soft launch late last year
        with subscribers but is now opening to the public market. To date,
        TimePlay has captured 6 million app downloads and 32 million gameplays
        in Canada and counting! For more information, visit{' '}
        <PopOpenLink href="http://www.timeplay.tv">www.timeplay.tv</PopOpenLink>
        .
      </p>

      <p>
        <b>About TimePlay:</b>
      </p>

      <p>
        TimePlay is a cloud-based casual interactive gaming company that
        delivers a new level of entertainment, with technology and gameplay that
        brings people together through group experiences, creating fun and
        social connections. TimePlay transforms mobile devices into game
        controllers, empowering people to engage with content on secondary
        screens in real-time, turning watchers into participants. Its deep
        history of immersive experiences launched in cinema and cruise lines,
        with hosted game shows and innovative pre-shows. TimePlay now delivers
        its engaging interactivity created in-venue, directly to consumers’
        homes, connecting living rooms across the globe. Through its patented
        technology, TimePlay’s two-way platform has driven significant
        participation and entertainment levels, enriched social competition and
        rich gamification for leading brands around the world &ndash; with 6
        million app downloads and over 32 million gameplays in Canada and
        counting! Visit{' '}
        <PopOpenLink href="http://www.timeplay.com">
          www.timeplay.com
        </PopOpenLink>{' '}
        and{' '}
        <PopOpenLink href="http://www.timeplay.tv">www.timeplay.tv</PopOpenLink>{' '}
        to learn more.
      </p>

      <p>
        For media inquiries, please contact:
        <br />
        <PopOpenLink href="mailto:ally@route504pr.com">
          Ally LaMere-Shedden
        </PopOpenLink>
        <br />
        Route 504 PR
        <br />
        <PopOpenLink href="mailto:ally@route504pr.com">
          ally@route504pr.com
        </PopOpenLink>
      </p>
    </Container>
  );
};

export default Press;
