import { createMedia } from '@artsy/fresnel';
import styled from '@emotion/styled';

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

export const PageSection = styled.div`
  padding: 8em 2em;
  font-size: 16px;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 12em;

  @media (max-width: 800px) {
    margin: 0 2em;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  font-family: Poppins, sans-serif;
  flex-direction: column;
  width: 200px;
  margin: 2em;
`;

export const ContentHeader = styled.h2`
  font-family: Poppins, sans-serif;
  font-size: 48px;
`;

export const InfoImage = styled.img`
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
`;
