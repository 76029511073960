import React from 'react';
import AppStoreSection, {
  AppStoreSectionType,
} from '../component/AppStoreSection';
import { DialogModal } from './DialogModal';

interface Props {
  open: boolean;
  message?: JSX.Element;
  onClose?: () => void;
}

export const InstallAppModal: React.FC<Props> = ({
  open,
  message,
  onClose,
}) => (
  <DialogModal
    title="Install TimePlay App"
    message={
      <>
        {message ? (
          message
        ) : (
          <p>Please install the TimePlay App on your phone to play.</p>
        )}
        <AppStoreSection
          type={AppStoreSectionType.InstallApp}
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-around',
            margin: 'auto',
            height: '120px',
            gap: '12px 12px',
          }}
        />
      </>
    }
    open={open}
    onClose={onClose}
  />
);
