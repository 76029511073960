import styled from '@emotion/styled';

export const LinkButton = styled.button`
  font-family: Poppins, sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
`;
