import styled from '@emotion/styled';
import React from 'react';
import { PopOpenLink } from '../component/PopOpenLink';

const FooterTextStyle = styled.div`
  & > a {
    color: #03cffb;
  }

  & > a:hover {
    color: #008eac;
  }
`;

const CopyText: React.FC<{}> = () => (
  <>
    &copy;2021-2022 - TimePlay Inc. | All rights reserved.
    <br />
    For all questions and inquiries, please contact{' '}
    <PopOpenLink href="mailto:support@timeplay.com">
      support@timeplay.com
    </PopOpenLink>
    <br />
    For all media inquiries, please contact{' '}
    <PopOpenLink href="mailto:info@timeplay.com">info@timeplay.com</PopOpenLink>
    <br />
  </>
);

const Footer: React.FC<{}> = () => {
  return (
    <div
      style={{
        backgroundColor: '#abe810',
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      <footer
        style={{
          padding: '2em',
        }}
      >
        <CopyText />
      </footer>
    </div>
  );
};

export default Footer;
