import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import { useEffect } from 'react';
import { Button, Dimmer } from 'semantic-ui-react';
import SimpleBar from 'simplebar-react';

const CloseButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;

  &:focus {
    background: #342e35 !important;
    color: #e8e318 !important;
  }
`;

interface PopupOverlayProps {
  backgroundColor?: string;
  textColor?: string;
  buttonStyle?: CSSProperties;
  toggled: boolean;
  handleClose: () => void;
}

export const PopupOverlay: React.FC<PopupOverlayProps> = ({
  backgroundColor,
  textColor,
  buttonStyle,
  toggled,
  handleClose,
  children,
}) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (!toggled) {
      return;
    }
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  });

  return (
    <>
      <Dimmer
        style={{
          zIndex: 999,
        }}
        active={toggled}
        page
      >
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            height: '75vh',
            width: '75vw',
            borderRadius: '2em',
            zIndex: 1000,
            transform: 'translate(-50%,-50%)',
            backgroundColor: backgroundColor || 'white',
            color: textColor || 'black',
          }}
        >
          <SimpleBar
            style={{
              maxHeight: '90%',
              textAlign: 'left',
              marginTop: '5%',
            }}
          >
            {children}
          </SimpleBar>
          <CloseButton
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              margin: '1em',
              padding: '0.25em 0.4em',
              fontFamily: 'Poppins, sans-serif',
              borderRadius: '4em',
              ...buttonStyle,
            }}
            onClick={handleClose}
            size="huge"
          >
            X
          </CloseButton>
        </div>
      </Dimmer>
    </>
  );
};
