import styled from '@emotion/styled';
import React, { useContext, useState } from 'react';
import { Dimmer, Menu, Segment, Sidebar, Sticky } from 'semantic-ui-react';
import tptvLogo from '../../assets/TPTV_logo.svg';
import { LinkButton } from '../../component/LinkButton';
import GlobalContext from '../../context/GlobalContext';

const MenuButtonItem = styled(Menu.Item)`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TPTVLogo = styled(Menu.Item)`
  margin-top: 4px !important;

  @media (max-width: 374px) {
    width: 70% !important;
  }

  & > img {
    width: 80% !important;

    @media (max-width: 374px) {
      width: 100% !important;
    }
  }
`;

interface Props {
  handleFAQOpen: () => void;
  handleFAQClose: () => void;
  handleNewsOpen: () => void;
  handleNewsClose: () => void;
}

const Navbar: React.FC<Props> = ({
  handleFAQOpen,
  handleFAQClose,
  handleNewsOpen,
  handleNewsClose,
  children,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(!sidebarOpened);
  const globalContext = useContext(GlobalContext);

  const switchPage = () => {
    handleSidebarHide();
    handleFAQClose();
    handleNewsClose();
  };

  return (
    <>
      <Sticky>
        <Menu
          secondary
          fixed="top"
          style={{
            backgroundColor: '#0032b0',
          }}
        >
          <TPTVLogo as="a" href="#top" position="left" onClick={handleFAQClose}>
            <img src={tptvLogo} alt="TimePlay TV" />
          </TPTVLogo>
          <Menu.Menu position="right">
            <Menu.Item
              as={LinkButton}
              className="link"
              icon={{
                name: 'sidebar',
                size: 'big',
              }}
              style={{
                color: '#F9F9F9',
                marginTop: '0.5em',
                marginLeft: '0.5em',
              }}
              onClick={handleToggle}
            />
          </Menu.Menu>
          <Dimmer
            style={{
              backgroundColor: 'rgba(0, 30, 106, 0.87)',
            }}
            active={sidebarOpened}
          />
        </Menu>
      </Sticky>
      <Sidebar.Pushable
        as={Segment}
        style={{
          transform: 'none',
          margin: 0,
          borderWidth: 0,
          borderRadius: 0,
        }}
      >
        <Sticky>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            direction="right"
            inverted
            onHide={handleSidebarHide}
            vertical
            visible={sidebarOpened}
            width="thin"
            style={{
              display: globalContext.newsletterDisplayed ? 'none' : 'initial',
              backgroundColor: '#0032b0',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            <Menu.Item
              as="a"
              href="#top"
              onClick={() => {
                switchPage();
              }}
            >
              Home
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#social"
              onClick={() => {
                switchPage();
              }}
            >
              Shout Outs
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#about"
              onClick={() => {
                switchPage();
              }}
            >
              Tell Me More
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#howtoplay"
              onClick={() => {
                switchPage();
              }}
            >
              How Do I Play
            </Menu.Item>
            <Menu.Item
              as="a"
              href="#tips"
              onClick={() => {
                switchPage();
              }}
            >
              Newbie Tips
            </Menu.Item>
            <MenuButtonItem
              as={LinkButton}
              className="link"
              onClick={() => {
                switchPage();
                handleFAQOpen();
              }}
            >
              FAQ
            </MenuButtonItem>
            <MenuButtonItem
              as={LinkButton}
              className="link"
              onClick={() => {
                switchPage();
                handleNewsOpen();
              }}
            >
              News
            </MenuButtonItem>
          </Sidebar>
        </Sticky>

        <Sidebar.Pusher dimmed={sidebarOpened}>{children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default Navbar;
