import styled from '@emotion/styled';
import { PageSection } from './common';

const EndSection = styled(PageSection)`
  padding: 6em 4em;
  font-size: 2em;

  @media (max-width: 767px) {
    padding: 12em 1em;
    font-size: 0.2em;
  }
`;

export const End: React.FC<{}> = () => (
  <EndSection
    style={{
      backgroundColor: '#0032b0',
    }}
  >
    <h2
      style={{
        fontFamily: 'Poppins Bold, sans-serif',
        fontSize: '48px',
        color: '#abe810',
      }}
    >
      See you on the leaderboard!
    </h2>
  </EndSection>
);
