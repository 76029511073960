import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGTMTracking = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || '',
      auth: process.env.REACT_APP_GTM_AUTH || '',
      preview: process.env.REACT_APP_GTM_PREVIEW || '',
    });
  }, []);
};

export default useGTMTracking;
