// import moment from 'moment';
import React from 'react';
import { Header, Icon, Image } from 'semantic-ui-react';
import styled from '@emotion/styled';
import PromoGraphic from '../assets/HomePage IMG.png';
import { keyframes } from '@emotion/react';
import AppStoreSection from '../component/AppStoreSection';
import ThankYou from '../assets/ThankYou.png';
import { PopOpenLink } from '../component/PopOpenLink';

// TODO(HOME2-674) Fix dimensions of elements
const HeroArea = styled.div`
  width: 100vw;
  height: 100%;
  padding: 0 6em;
`;

const MainInfoArea = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

// TODO(HOME2-674) Fix dimensions of elements
const TimePlayAttract = styled.div`
  position: relative;
  width: 50vw;
  pointer-events: none;

  @media (max-width: 179vh) {
    transform: translate(0, -120px);
  }

  @media (max-width: 149vh) {
    transform: translate(0, -180px);
  }
`;

// TODO(HOME2-674) Fix dimensions of elements
const TimePlayAttractImage = styled.img`
  height: 90%;
  position: absolute;
  left: -80px;
  bottom: 0;

  @media (max-width: 179vh) {
    height: 70%;
  }

  @media (max-width: 149vh) {
    height: 60%;
  }
`;

const TimePlayAttractImageMobile = styled.img`
  width: 100%;
  margin-top: 0.8em;

  @media (min-width: 1023px) {
    width: 60%;
  }
`;

const NextShow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: initial;
`;

const SeeMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 2.25em;
  left: 50%;
  font-size: 1rem;
  z-index: 700;

  @media (min-width: 768px) {
    position: absolute;
  }
`;

const upDown = keyframes`
  0% {
    transform: rotate(-45deg) translate(0,0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
`;

const SeeMoreImage = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
  box-sizing: border-box;
  animation: ${upDown} 2s linear infinite;
`;

const SocialSection = styled.div`
  display: flex;
  flex-flow: row;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 4em;
    right: -22px;
  }
`;

const SocialSectionMobile = styled.div`
  display: flex;
  flex-flow: column;
  font-size: 1.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 400;
`;

const SocialLink = styled(PopOpenLink)`
  margin: 0 0.5em;
`;

const SocialIcon = styled(Icon)`
  color: #f9f9f9;
  transition: transform 0.25s;
  &:hover {
    color: #abe810;
    transform: scale(1.3);
  }
`;

const TaglineLabel = styled.div`
  & h1 {
    font-family: Poppins Bold, sans-serif;
    color: #abe810;
    font-size: 2em;
    line-height: 1.5;

    @media (max-width: 767px) {
      font-size: 1.5em;
    }
  }
`;

// TODO(HOME2-674) Fix dimensions of elements
const TaglineLabelDesktop = styled(TaglineLabel)`
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 100px;
  left: 200px;
  width: 800px;
  height: 154px;
  z-index: 400;

  @media (max-width: 1549px) {
    left: 100px;
  }

  @media (max-height: 749px) {
    bottom: 80px;
  }
`;

const PromoImage = styled(Image)`
  width: 350px;
`;

const PromoImageBackgroundMobile = styled(Image)`
  pointer-events: none;
  left: 30%;
  transform: translate(-30%, -30%);

  @media (max-width: 767px) {
    left: inherit;
    transform: translate(-25%, -25%);
  }

  @media (max-width: 539px) {
    left: 10%;
    transform: translate(-15%, -15%);
  }
`;

const ThankYouImage = () => (
  <Image
    src={ThankYou}
    style={{
      lineHeight: 1,
    }}
    alt="Thank you for an epic season! Stay tuned for what's coming up next."
  />
);

const TimePlayAttractAltText = 'TimePlay Live';

const DesktopHero: React.FC<{}> = () => {
  return (
    <Header
      style={{
        margin: 0,
      }}
      className="App-header"
    >
      <HeroArea>
        <MainInfoArea>
          <TimePlayAttract>
            <TimePlayAttractImage
              src={PromoGraphic}
              alt={TimePlayAttractAltText}
            />
            <TaglineLabelDesktop>
              <div
                style={{
                  backgroundColor: '#0032b0',
                  width: '600px',
                  borderRadius: '8em',
                  pointerEvents: 'initial',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <h1>
                  An interactive experience
                  <br />
                  you can play from home!
                </h1>
              </div>
            </TaglineLabelDesktop>
          </TimePlayAttract>
          <NextShow>
            <span
              style={{
                marginBottom: '1em',
                fontSize: '1rem',
                color: '#abe810',
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              {/* Next show - */}
              <span
                style={{
                  // marginLeft: '0.5em',
                  fontSize: '3.25rem',
                  fontFamily: 'Poppins Bold, sans-serif',
                }}
              >
                {/* {moment(startTime).format('dddd, hh:mm a')} */}
                {/* {startLabel} */}
                {/* <Image
                  style={{
                    position: 'absolute',
                    right: '-40%',
                    top: 0,
                    pointerEvents: 'none',
                  }}
                  src={ThreeAWeekBG}
                /> */}
                {/* <PromoImage src={ThreeAWeek} /> */}
                <ThankYouImage />
              </span>
            </span>
            {/* <GameCountdown date={moment(startTime).toString()} /> */}
            {/* <GameCalendar /> */}
            <br
              style={{
                marginBottom: '2em',
              }}
            />
            <AppStoreSection
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: '100px', // precomputed height in Safari is messed up, have to explicitly set a height
                zIndex: 100,
              }}
            />
          </NextShow>
        </MainInfoArea>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <SeeMoreSection>
            <a
              style={{
                width: '60px',
                height: '60px',
                transform: 'translate(-50%,0)',
              }}
              href="#social"
              aria-label="See More"
            >
              <SeeMoreImage />
            </a>
          </SeeMoreSection>
          <SocialSection>
            <span
              style={{
                marginRight: '1.5em',
                color: '#a2e0dc',
              }}
            >
              Follow us to get the latest updates!
            </span>
            <SocialLink
              href="https://twitter.com/timeplayent?lang=en"
              title="Twitter"
              aria-label="Twitter"
            >
              <SocialIcon name="twitter" size="large" />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/TimeplayEnt/"
              title="Facebook"
              aria-label="Facebook"
            >
              <SocialIcon name="facebook f" size="large" />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/timeplay/?hl=en"
              title="Instagram"
              aria-label="Instagram"
            >
              <SocialIcon name="instagram" size="large" />
            </SocialLink>
          </SocialSection>
        </div>
      </HeroArea>
    </Header>
  );
};

const MobileHero: React.FC<{}> = () => {
  return (
    <Header
      style={{
        margin: 0,
        height: '100%',
      }}
      className="App-header"
    >
      <div
        style={{
          width: '100vw',
          height: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            marginTop: '4em',
          }}
        >
          <TimePlayAttractImageMobile
            src={PromoGraphic}
            alt={TimePlayAttractAltText}
          />
        </div>
        <TaglineLabel>
          <h1>
            An interactive experience
            <br />
            you can play from home!
          </h1>
        </TaglineLabel>
        <NextShow
          style={{
            margin: '2em 0',
            marginTop: '8em',
          }}
        >
          <span
            style={{
              marginBottom: '1em',
              fontSize: '1rem',
              color: '#abe810',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            {/* Next show - */}
            <span
              style={{
                fontSize: '3.25rem',
                fontFamily: 'Poppins Bold, sans-serif',
              }}
            >
              {/* {moment(startTime).format('dddd, hh:mm a')} */}
              {/* {startLabel} */}
              {/* <PromoImageBackgroundMobile
                style={{
                  position: 'absolute',
                }}
                src={ThreeAWeekBG}
              /> */}
              {/* <PromoImage src={ThreeAWeek} /> */}
              <ThankYouImage />
            </span>
          </span>
          <div>
            {/* <GameCountdown date={moment(startTime).toString()} /> */}
            {/* <GameCalendar
              style={{
                zIndex: 100,
                position: 'relative',
              }}
            /> */}
          </div>
          <AppStoreSection
            style={{
              display: 'flex',
              height: '200px',
            }}
          />
          <SocialSectionMobile>
            <span
              style={{
                color: '#a2e0dc',
              }}
            >
              Follow us to get the latest updates!
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: 'auto',
                marginTop: '1.5em',
              }}
            >
              <SocialLink
                href="https://twitter.com/timeplayent?lang=en"
                title="Twitter"
                aria-label="Twitter"
              >
                <SocialIcon name="twitter" size="large" />
              </SocialLink>
              <SocialLink
                href="https://www.facebook.com/TimeplayEnt/"
                title="Facebook"
                aria-label="Facebook"
              >
                <SocialIcon name="facebook f" size="large" />
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/timeplay/?hl=en"
                title="Instagram"
                aria-label="Instagram"
              >
                <SocialIcon name="instagram" size="large" />
              </SocialLink>
            </div>
          </SocialSectionMobile>
        </NextShow>
      </div>
    </Header>
  );
};

export { DesktopHero, MobileHero };
