import styled from '@emotion/styled';
import React from 'react';
import {
  ContentBox,
  ContentHeader,
  ContentItem,
  InfoImage,
  PageSection,
} from './common';
import UpdateIcon from '../assets/UpdateIcon.svg';
import NotificationIcon from '../assets/NotificationIcon.png';
import ChromeIcon from '../assets/ChromeIcon.svg';

const NewbieImage = styled(InfoImage)`
  width: 150px;
  height: 150px;
`;

export const NewbieTips: React.FC<{}> = () => (
  <PageSection
    id="tips"
    style={{
      backgroundColor: '#abe810',
    }}
  >
    <ContentHeader>Newbie Tips</ContentHeader>
    <ContentBox>
      <ContentItem>
        <NewbieImage src={UpdateIcon} alt="" />
        <p>
          Update your TimePlay app{' '}
          <b>
            (
            <a
              style={{
                color: '#342E35',
              }}
              href="https://apps.apple.com/ca/app/timeplay/id462974334"
            >
              iOS
            </a>{' '}
            <a
              style={{
                color: '#342E35',
              }}
              href="https://play.google.com/store/apps/details?id=com.timeplay&hl=en_CA"
            >
              Android
            </a>
            )
          </b>{' '}
          and open prior to showtime to get setup.
        </p>
      </ContentItem>
      <ContentItem>
        <NewbieImage src={NotificationIcon} alt="" />
        <p>
          Get reminders by turning on your TimePlay app notifications by going
          into settings on your device.
        </p>
      </ContentItem>
      <ContentItem>
        <NewbieImage src={ChromeIcon} alt="" />
        <p>
          Use your Chrome browser for the best viewing and playing experience.
        </p>
      </ContentItem>
    </ContentBox>
  </PageSection>
);
