import React, { useState } from 'react';

import './App.css';
import 'semantic-ui-css/semantic.min.css';
// import moment from 'moment';
// import 'moment-timezone';
import 'simplebar/dist/simplebar.min.css';
import SiteContainer from './site/SiteContainer';
import usePageTracking from './hooks/usePageTracking';
import { SmoothScrollProvider } from './context/SmoothScrollContext';
import useGTMTracking from './hooks/useGTMTracking';
import { GlobalContextProvider } from './context/GlobalContext';

// const getThisThursday = (timezone: string) => {
//   let now = moment().tz(timezone);
//   let thisThursday = moment()
//     .tz(timezone)
//     .startOf('week')
//     .add(4, 'd')
//     .hour(20)
//     .minute(30);
//   return now.isAfter(thisThursday.clone().add(30, 'minute'))
//     ? thisThursday.clone().add(1, 'weeks')
//     : thisThursday;
// };

function App() {
  usePageTracking();
  useGTMTracking();

  return (
    <GlobalContextProvider>
      <SmoothScrollProvider>
        <div className="App">
          <SiteContainer />
        </div>
      </SmoothScrollProvider>
    </GlobalContextProvider>
  );
}

export default App;
