import React from 'react';
import {
  PageSection,
  ContentHeader,
  ContentBox,
  ContentItem,
  InfoImage,
} from './common';
import About1 from '../assets/TPTV IMG1.png';
import About2 from '../assets/Lobby For Splash Page.png';
import About3 from '../assets/TPTV Leaderboard 2.png';
// import About4 from '../assets/SCENE.png';

export const About: React.FC<{}> = () => (
  <PageSection
    id="about"
    style={{
      backgroundColor: '#abe810',
    }}
  >
    <ContentHeader>Tell Me More</ContentHeader>
    <ContentBox>
      <ContentItem>
        <InfoImage src={About1} alt="" />
        <p>
          Our technology transforms your phone into a rich game controller to
          play movie trivia on a second screen.
        </p>
      </ContentItem>
      <ContentItem>
        <InfoImage src={About2} alt="" />
        <p>Play in smaller groups of virtual pods to compete with others.</p>
      </ContentItem>
      <ContentItem>
        <InfoImage src={About3} alt="" />
        <p>
          Get ready for some fun-filled competition, tons of prizes and live
          leaderboards!
        </p>
      </ContentItem>
      {/* <ContentItem>
        <InfoImage src={About4} alt="" />
        <p>
          Top players in each pod win SCENE points. Not a winner? No problem, we
          reward SCENE points just for playing!
        </p>
      </ContentItem> */}
    </ContentBox>
  </PageSection>
);
