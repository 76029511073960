import { useEffect, useState } from 'react';

// TODO fix unmounting issue that sometimes happens when switching from mobile to desktop views and back again
export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);

  // This is needed to ensure that scroll position is always updated even when component tree changes
  useEffect(() => {
    setScrollPosition(
      document.body.scrollTop || document.documentElement.scrollTop,
    );
  }, [setScrollPosition]);

  // This effect tracks scroll and updates scroll position accordingly
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollPosition(
        document.body.scrollTop || document.documentElement.scrollTop,
      );
    });
  }, []);

  return scrollPosition;
}
