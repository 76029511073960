import styled from '@emotion/styled';
import React, { useState } from 'react';
import AppLink from '../component/AppLink';
import {
  PageSection,
  ContentBox,
  ContentItem,
  Media,
  MediaContextProvider,
  InfoImage,
  ContentHeader,
} from './common';
import Step1 from '../assets/Step1.png';
import Step2 from '../assets/Step2.png';
import Step3 from '../assets/Step3.png';
import Laptop from '../assets/Laptop-And-Mobile-Mock-TPTV.png';
import { Parallax } from 'react-scroll-parallax';
import { InstallAppModal } from '../modal/InstallAppModal';

const LaptopImageComponent = () => <LaptopImage src={Laptop} alt="" />;

const HowToPlayDesktop: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        margin: '0 6em',
        height: '420px',
      }}
    >
      <Parallax
        styleOuter={{
          transform: 'translate(-5em,-6em)',
        }}
        y={[-5, 5]}
      >
        <LaptopImageComponent />
      </Parallax>
      <div
        style={{
          width: '24em',
        }}
      >
        <ContentHeader
          style={{
            color: '#ABE810',
          }}
        >
          How Do I Play
        </ContentHeader>
        <span
          style={{
            color: '#F9F9F9',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '24px',
            lineHeight: 1.5,
          }}
        >
          All you need is your phone
          <br />
          and a second screen
        </span>
      </div>
    </div>
  );
};

const HowToPlayMobile: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        <ContentHeader
          style={{
            color: '#ABE810',
          }}
        >
          How Do I Play
        </ContentHeader>
        <span
          style={{
            color: '#F9F9F9',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '24px',
            lineHeight: 1.5,
          }}
        >
          All you need is your phone and a second screen
        </span>
      </div>
      <LaptopImageContainerMobile>
        <LaptopImageComponent />
      </LaptopImageContainerMobile>
    </div>
  );
};

const LaptopImageContainerMobile = styled.div`
  position: relative;
  display: inline-block;
  margin: auto;
  margin-top: 4em;
  margin-bottom: 2em;
  transform: translate(-36px, 0);

  @media (max-width: 359px) {
    transform: translate(-60px, 0);
  }
`;

const LaptopImage = styled.img`
  width: 40em;

  @media (min-width: 1366px) {
    transform: translate(0, -6em);
  }

  @media (max-width: 1365px) {
    width: 30em;
    transform: translate(10%, 10%);
  }

  @media (max-width: 767px) {
    width: 20em;
  }
`;

const StepImage = styled(InfoImage)`
  transform: translate(-0.8em, 0);
`;

export const HowToPlay: React.FC<{}> = () => {
  const [displayMobileModal, setDisplayMobileModal] = useState(false);

  return (
    <>
      <InstallAppModal
        open={displayMobileModal}
        onClose={() => setDisplayMobileModal(false)}
      />
      <PageSection
        id="howtoplay"
        style={{
          backgroundColor: '#0032b0',
        }}
      >
        <MediaContextProvider>
          <Media lessThan="computer">
            <HowToPlayMobile />
          </Media>
          <Media greaterThanOrEqual="computer">
            <HowToPlayDesktop />
          </Media>
        </MediaContextProvider>
        <ContentBox>
          <ContentItem
            style={{
              color: '#F9F9F9',
            }}
          >
            <StepImage src={Step1} alt="Step 1" />
            <p>
              Open the{' '}
              <AppLink
                onDesktopToggle={() => {
                  setDisplayMobileModal(true);
                }}
              />{' '}
              and select TimePlay Live. Follow the instructions on your phone.
            </p>
          </ContentItem>
          <ContentItem
            style={{
              color: '#F9F9F9',
            }}
          >
            <StepImage src={Step2} alt="Step 2" />
            <p>
              Go to timeplay.tv on your second screen (smart TV or PC). Also
              compatible with the browser on your Amazon Fire TV.
            </p>
          </ContentItem>
          <ContentItem
            style={{
              color: '#F9F9F9',
            }}
          >
            <StepImage src={Step3} alt="Step 3" />
            <p>
              Connect your phone to the screen by scanning the QR code and get
              ready to play!
            </p>
          </ContentItem>
        </ContentBox>
      </PageSection>
    </>
  );
};
