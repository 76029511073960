import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import tptvLogo from '../../assets/TPTV_logo.svg';
import useScrollPosition from '../../hooks/useScrollPosition';
import { LinkButton } from '../../component/LinkButton';

const TopMenuLinkStyle = `
  font-family: Poppins, sans-serif;
  margin: 0.5em 1.5em;
  color: white;

  &:hover {
    color: #abe810;
  }
`;

const TopMenuLink = styled.a`
  ${TopMenuLinkStyle}
`;

const TopMenuLinkButton = styled(LinkButton)`
  ${TopMenuLinkStyle}
`;

const TopElement = styled.div`
  position: absolute;
  display: inline-block;

  @media (max-width: 1179px) {
    font-size: 0.8em;
  }
`;

interface Props {
  heroHeight: number;
  handleFAQOpen: Function;
  handleNewsOpen: Function;
}

const Navbar: React.FC<Props> = ({
  heroHeight,
  handleFAQOpen,
  handleNewsOpen,
}) => {
  const scrollPosition = useScrollPosition();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuRef.current) {
      if (heroHeight > 0 && scrollPosition >= heroHeight - 200) {
        // menuRef.current.style.boxShadow = '0 2px 10px black';
        menuRef.current.style.position = 'fixed';
        menuRef.current.style.padding = '2em 4em';
        menuRef.current.style.transition = 'box-shadow 0.5s, padding 0.5s';
        menuRef.current.style.zIndex = '950';
      } else {
        // menuRef.current.style.boxShadow = '';
        menuRef.current.style.position = 'absolute';
        menuRef.current.style.padding = '4em 6em';
        menuRef.current.style.transition = 'none';
        menuRef.current.style.zIndex = '0';
      }
    }
  }, [scrollPosition]);

  return (
    <div
      ref={menuRef}
      id="top-menu"
      style={{
        position: 'absolute',
        top: 0,
        display: 'flex',
        backgroundColor: '#0032b0',
        width: '100vw',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // even though absolute children are separated from flex ordering, align-items will center the items vertically
          position: 'relative',
          width: '100vw',
        }}
      >
        <TopElement
          style={{
            left: '-64px',
          }}
        >
          <a href="#top">
            <img
              style={{
                width: '70%',
                verticalAlign: 'middle',
              }}
              src={tptvLogo}
              alt="TimePlay TV"
            ></img>
          </a>
        </TopElement>
        <TopElement
          style={{
            right: '12px',
          }}
        >
          <TopMenuLink href="#social">Shout Outs</TopMenuLink>
          <TopMenuLink href="#about">Tell Me More</TopMenuLink>
          <TopMenuLink href="#howtoplay">How Do I Play</TopMenuLink>
          <TopMenuLink href="#tips">Newbie Tips</TopMenuLink>
          <TopMenuLinkButton
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              handleFAQOpen();
            }}
          >
            FAQ
          </TopMenuLinkButton>
          <TopMenuLinkButton
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              handleNewsOpen();
            }}
          >
            News
          </TopMenuLinkButton>
        </TopElement>
      </div>
    </div>
  );
};

export default Navbar;
