import { createContext, useEffect, useState } from 'react';

type SmoothScrollContextType = {
  smoothScrollEnabled: boolean;
  setSmoothScrollEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const SmoothScrollContext = createContext<SmoothScrollContextType | null>(null);

export default SmoothScrollContext;

function anchorListener(this: any, e: Event) {
  e.preventDefault();
  (
    document.querySelector(this.getAttribute('href')) ||
    document.querySelector('#root')
  )?.scrollIntoView({
    behavior: 'smooth',
  });
}

export const SmoothScrollProvider: React.FC<{}> = ({ children }) => {
  const [smoothScrollEnabled, setSmoothScrollEnabled] = useState(true);

  useEffect(() => {
    if (!smoothScrollEnabled) return;

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', anchorListener);
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', anchorListener);
      });
    };
  }, [smoothScrollEnabled]);

  return (
    <SmoothScrollContext.Provider
      value={{
        smoothScrollEnabled,
        setSmoothScrollEnabled,
      }}
    >
      {children}
    </SmoothScrollContext.Provider>
  );
};
