import { CSSProperties } from 'react';
import AppStoreBadge from '../assets/AppStore_badge.svg';
import PlayStoreBadge from '../assets/PlayStore_badge.svg';

export enum AppStoreSectionType {
  Title,
  InstallApp,
}

interface Props {
  style?: CSSProperties;
  type?: AppStoreSectionType;
}

const AppStoreSection: React.FC<Props> = ({
  style,
  type = AppStoreSectionType.Title,
}) => (
  <div style={style}>
    <a
      href="https://apps.apple.com/ca/app/timeplay/id462974334"
      target="_blank"
      rel="nofollow noopener"
      style={{
        display: 'flex',
        overflow: 'hidden',
        marginRight: type === AppStoreSectionType.Title ? '2em' : 0,
      }}
    >
      <img
        src={AppStoreBadge}
        alt="Download on the App Store"
        style={{
          display: 'inline-block',
          width: '136px',
        }}
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.timeplay&hl=en_CA"
      target="_blank"
      rel="nofollow noopener"
      style={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <img
        alt="Get it on Google Play"
        src={PlayStoreBadge}
        style={{
          display: 'inline-block',
          width: '150px',
        }}
      />
    </a>
  </div>
);

export default AppStoreSection;
