import styled from '@emotion/styled';
import { Icon, Image } from 'semantic-ui-react';
import { ContentHeader, PageSection } from './common';

import Social1 from '../assets/social/1.png';
import Social2 from '../assets/social/2.png';
import Social3 from '../assets/social/3.png';
import Social4 from '../assets/social/4.png';
import Social5 from '../assets/social/5.png';
import Social6 from '../assets/social/6.png';
import Social7 from '../assets/social/7.png';
import Social8 from '../assets/social/8.png';
import BigTweet from '../assets/social/Monica D. Relda.png';
import Tweet1 from '../assets/social/Sara-Alfred.png';
import Tweet2 from '../assets/social/Casie-Stewart.png';
import Tweet3 from '../assets/social/Sydra.png';
import React from 'react';
import { PopOpenLink } from '../component/PopOpenLink';

const SocialGrid = styled.div`
  display: grid;
  grid-template-columns: [col1-start] 1fr [col1-end col2-start] 1fr [col2-end];

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const SocialDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5em;
`;

const SocialImage = styled(Image)``;

const StoryDiv = styled.div`
  flex: 1 0 20%;
  margin: 0.5em;

  @media (max-width: 1023px) {
    flex: 0 0 34%;
  }

  @media (max-width: 767px) {
    flex: 1 0 100%;
  }
`;

const StoryImage = styled(SocialImage)`
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: 4px solid white;
  border-radius: 0.85em;

  @media (max-width: 767px) {
    border-radius: 1em;
  }
`;

const TweetDiv = styled.div`
  margin: 0.05em;
`;

const TweetImage = styled(SocialImage)`
  width: 100%;
  margin: 0.125em 0;

  @media (max-width: 1023px) {
    margin: 0.07em 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const SocialLink = styled(PopOpenLink)`
  margin: 0 0.5em;
`;

const SocialIcon = styled(Icon)`
  color: #f9f9f9;
  transition: transform 0.25s;
  &:hover {
    color: #abe810;
    transform: scale(1.3);
  }
`;

const SocialSection = styled(PageSection)`
  padding: 2em 2em 2em 2em;
  font-size: 2em;
  line-height: 1.25;

  @media (max-width: 767px) {
    padding: 6em 1em;
    font-size: 1.5em;
  }
`;

const SocialIntroSection = styled(PageSection)`
  padding: 4em 2em 2em 2em;
  font-size: 2em;
  line-height: 1.25;

  @media (max-width: 767px) {
    padding: 6em 1em;
    font-size: 1.5em;
  }
`;

const SocialThanks = styled.span`
  margin: 1em;
  width: 80vw;
  color: #abe810;
  line-height: 1.25;

  @media (max-width: 1365px) {
    width: 200vw;
  }
`;

export const Social: React.FC<{}> = () => (
  <>
    <SocialIntroSection
      id="social"
      style={{
        backgroundColor: '#abe810',
      }}
    >
      <ContentHeader>Shout Outs</ContentHeader>
      <div
        style={{
          margin: '1em',
        }}
      >
        <span>
          We’ve got THE BEST fans, and they love sharing their experience and
          excitement with friends and family. Check out what our players are
          saying on social.
        </span>
      </div>
    </SocialIntroSection>
    <SocialSection
      style={{
        backgroundColor: '#0032b0',
      }}
    >
      <SocialGrid>
        <SocialDiv
          style={{
            gridColumn: 'col1-start / col2-end',
          }}
        >
          <StoryDiv>
            <StoryImage src={Social1} alt="Story from afeastyjourney" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social2} alt="Story from lindsiedee" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social3} alt="Story from sarah.houghton_" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social4} alt="Story from casiestewart" />
          </StoryDiv>
        </SocialDiv>
        <SocialDiv
          style={{
            gridColumn: 'col1-start / col1-end',
          }}
        >
          <TweetDiv>
            <TweetImage src={BigTweet} alt="Tweet from Monica D. Reida" />
          </TweetDiv>
        </SocialDiv>
        <SocialDiv
          style={{
            gridColumn: 'col2-start / col2-end',
          }}
        >
          <TweetDiv
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <TweetImage src={Tweet1} alt="Tweet from Sara Alfred" />
            <TweetImage src={Tweet2} alt="Tweet from Casie Stewart" />
            <TweetImage src={Tweet3} alt="Tweet from Sydra" />
          </TweetDiv>
        </SocialDiv>
        <SocialDiv
          style={{
            gridColumn: 'col1-start / col2-end',
          }}
        >
          <StoryDiv>
            <StoryImage src={Social5} alt="Story from jenelleefraser" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social6} alt="Story from terihart" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social7} alt="Story from anna_antropova" />
          </StoryDiv>
          <StoryDiv>
            <StoryImage src={Social8} alt="Story from tonififi" />
          </StoryDiv>
        </SocialDiv>
      </SocialGrid>
      <div
        style={{
          display: 'flex',
        }}
      >
        <span
          style={{
            borderTop: `1px solid white`,
            width: '100%',
            height: '1px',
            margin: 'auto',
          }}
        ></span>
        <SocialThanks>
          Thank you TimePlayers!
          <br />
          DM us on social if you want to be featured on our page.
        </SocialThanks>
        <span
          style={{
            borderTop: `1px solid white`,
            width: '100%',
            height: '1px',
            margin: 'auto',
          }}
        ></span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SocialLink
          href="https://twitter.com/timeplayent?lang=en"
          title="Twitter"
          aria-label="Twitter"
        >
          <SocialIcon name="twitter" size="large" />
        </SocialLink>
        <SocialLink
          href="https://www.facebook.com/TimeplayEnt/"
          title="Facebook"
          aria-label="Facebook"
        >
          <SocialIcon name="facebook f" size="large" />
        </SocialLink>
        <SocialLink
          href="https://www.instagram.com/timeplay/?hl=en"
          title="Instagram"
          aria-label="Instagram"
        >
          <SocialIcon name="instagram" size="large" />
        </SocialLink>
      </div>
    </SocialSection>
  </>
);
