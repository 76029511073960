import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { Button } from '../component/Button';
import { DialogModal } from '../modal/DialogModal';
import ReactGA from 'react-ga';
import { useRef } from 'react';

const EmailInput = styled.input`
  margin: 0.5em auto;
  padding: 0.6em;
  font-size: 24px;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  outline: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);

  &::placeholder {
    font-size: 0.75em;
    line-height: 1.5;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

const CheckboxBox = styled.div`
  display: flex;
  justify-content: center;

  & > input + span {
    width: 10%;
    position: absolute;
    transform: translate(1.4em, 0.25em);
    pointer-events: none;
  }

  & > input:checked + span::before {
    content: '✔';
  }
`;

const CheckboxInput = styled.input`
  appearance: none;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 0.1em;
  border: none;
  outline: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-bottom: 0.5em;

  &:focus {
    background-color: #f9f9f9;
  }
`;

interface EmailFormProps {
  status: 'error' | 'success' | 'sending' | null;
  message: string | Error | null;
  isConfirmed: boolean;
  onConfirmToggle: (checked: boolean) => void;
  onSuccess: () => void;
  submit: (data: EmailFormFields) => void;
}

const EmailForm: React.FC<EmailFormProps> = ({
  status,
  message,
  isConfirmed,
  onConfirmToggle,
  onSuccess,
  submit,
}) => {
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  let email: HTMLInputElement | null;
  const labelRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    if (!email || email.value.indexOf('@') === -1) {
      setIsEmailInvalid(true);
      if (!email) {
        ReactGA.event({
          category: 'Newsletter Signup',
          action: 'Submit Error',
          label: 'Email input missing',
        });
      } else {
        ReactGA.event({
          category: 'Newsletter Signup',
          action: 'Entered invalid email',
        });
      }
      return;
    }
    setIsEmailInvalid(false);
    submit({
      EMAIL: email.value,
    });
  };

  const errorText = (errorResp: string | undefined) => {
    if (!errorResp) {
      return '';
    }
    if (errorResp.includes('already subscribed')) {
      return 'You are already subscribed';
    } else if (errorResp.includes('Timeout')) {
      return 'Cannot subscribe due to timeout from server. Please try again later.';
    } else if (errorResp.includes('invalid email address')) {
      return 'Please enter a valid email';
    }
    return errorResp;
  };

  useEffect(() => {
    if (status === 'success') {
      ReactGA.event({
        category: 'Newsletter Signup',
        action: 'Signup Success',
      });
      onSuccess();
    } else if (status === 'error') {
      labelRef.current?.focus();
      ReactGA.event({
        category: 'Newsletter Signup',
        action: 'Signup Error',
        // Google Analytics redacts emails if they appear in the label, so we should pipe it through errorText method to get the "already subscribed" message
        label: errorText(message?.toString()),
      });
    }
  }, [status]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5em auto',
      }}
    >
      <div
        ref={labelRef}
        tabIndex={-1}
        style={{
          minHeight: '1.5em',
          textAlign: 'center',
          fontSize: '0.8em',
          opacity: status !== null || isEmailInvalid ? 1 : 0,
          outline: 'none',
        }}
      >
        {(status !== null || isEmailInvalid) && (
          <>
            {isEmailInvalid && (
              <span
                style={{
                  color: 'red',
                }}
              >
                Please enter a valid email
              </span>
            )}
            {!isEmailInvalid && status === 'sending' && (
              <span
                style={{
                  color: 'blue',
                }}
              >
                Sending...
              </span>
            )}
            {!isEmailInvalid && status === 'error' && (
              <span
                style={{
                  color: 'red',
                }}
                dangerouslySetInnerHTML={{
                  __html: errorText(message?.toString()),
                }}
              ></span>
            )}
            {!isEmailInvalid && status === 'success' && (
              <span
                style={{
                  color: 'green',
                }}
                dangerouslySetInnerHTML={{
                  __html: message?.toString() || '',
                }}
              ></span>
            )}
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          margin: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <EmailInput
          ref={(node) => (email = node)}
          type="text"
          placeholder="type your email address"
        ></EmailInput>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
          margin: '1em auto',
        }}
      >
        <CheckboxBox>
          <CheckboxInput
            type="checkbox"
            name="notifyme"
            onChange={(e) => onConfirmToggle(e.target.checked)}
            checked={isConfirmed}
          ></CheckboxInput>
          <span
            style={{
              color: '#0032B0',
              fontWeight: 'bold',
            }}
          ></span>
        </CheckboxBox>
        <label
          style={{
            lineHeight: 1.25,
            textAlign: 'center',
            fontSize: '1.15rem',
          }}
          htmlFor="notifyme"
        >
          Yes, I want to receive marketing communications from TimePlay.
          <br />I understand I can unsubscribe at any time.
        </label>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            display: 'inline-block',
            margin: 'auto 1em',
            padding: '1em 2em',
            borderRadius: '0.5rem',
            textAlign: 'center',
            boxShadow: '0 3px 6px rgba(0,0,0,0.25)',
          }}
          backgroundColor="#ce0f46"
          textColor="#fff"
          hoverBackgroundColor="#342E35"
          hoverTextColor="#E8E318"
          onClick={handleSubmit}
        >
          Sign me up!
        </Button>
      </div>
    </div>
  );
};

interface Props {
  onClose?: () => void;
}

export const NewsletterSignup: React.FC<Props> = ({ onClose }) => {
  const [notifyEnabled, setNotifyEnabled] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  return (
    <>
      <DialogModal
        open={openConfirmDialog}
        title="Please confirm"
        message={
          <>
            Make sure you check the box so we can let you know about updates and
            offers!
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
      />
      <DialogModal
        open={openSuccessDialog}
        title="Thanks for signing up!"
        message={
          <>
            A welcome email has been sent. Please check your inbox or junk mail
            folder.
          </>
        }
        onClose={() => {
          setOpenSuccessDialog(false);
          onClose?.();
        }}
      />
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL || ''}
        render={({ subscribe, status, message }) => {
          const submit = (formData: EmailFormFields) => {
            if (!notifyEnabled) {
              setOpenConfirmDialog(true);
              ReactGA.event({
                category: 'Newsletter Signup',
                action: 'Clicked signup button without confirming',
              });
              return;
            }
            subscribe(formData);
          };

          return (
            <EmailForm
              status={status}
              message={message}
              submit={submit}
              isConfirmed={notifyEnabled}
              onConfirmToggle={(checked) => setNotifyEnabled(checked)}
              onSuccess={() => setOpenSuccessDialog(true)}
            />
          );
        }}
      />
    </>
  );
};
