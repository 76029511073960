import React from 'react';
import ReactGA from 'react-ga';
import { LinkButton } from './LinkButton';

function isMobile() {
  return 'ontouchstart' in document.documentElement;
}

export const triggerAppLink = (label: string, onDesktopToggle: Function) => {
  ReactGA.event({
    category: 'App Link',
    action: 'Opened App Link',
    label: label,
  });
  if (isMobile()) {
    document.location.href = 'timeplay://open';
  } else {
    ReactGA.modalview('/install_app');
    onDesktopToggle();
  }
};

interface Props {
  onDesktopToggle: Function;
}

const AppLink: React.FC<Props> = ({ onDesktopToggle }) => {
  return (
    <>
      <LinkButton
        onClick={() => {
          triggerAppLink('App Link', onDesktopToggle);
        }}
      >
        <b
          style={{
            color: '#abe810',
          }}
        >
          TimePlay app
        </b>
      </LinkButton>
    </>
  );
};

export default AppLink;
