import React from 'react';
import { End } from '../section/End';
import { NewbieTips } from '../section/NewbieTips';
import { HowToPlay } from '../section/HowToPlay';
import { About } from '../section/About';
import { Social } from '../section/Social';

const Info: React.FC<{}> = () => (
  <div>
    <Social />
    <About />
    <HowToPlay />
    <NewbieTips />
    <End />
  </div>
);

export default Info;
