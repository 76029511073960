import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { NewsletterSignup } from '../form/NewsletterSignup';
import ReactGA from 'react-ga';
import { usePopupRef } from '../hooks/usePopupRef';

const NewsletterBack = styled(Modal)`
  background: rgb(171, 232, 16) !important;
  background: linear-gradient(
    0deg,
    rgba(171, 232, 16, 1) 0%,
    rgba(232, 227, 24, 1) 100%
  ) !important;
  transform-origin: center !important;

  @media (max-width: 767px), (orientation: landscape) and (max-width: 1023px) {
    transform: scale(0.6);
  }

  @media (max-width: 374px) {
    transform: scale(0.5);
  }

  @media (orientation: landscape) and (max-width: 1023px) and (max-height: 459px),
    (aspect-ratio: 1/1) and (max-height: 460px) {
    transform-origin: top !important;
  }
`;

const CloseButton = styled(Button)`
  background: rgb(171, 232, 16) !important;
  background: linear-gradient(
    0deg,
    rgba(171, 232, 16, 1) 0%,
    rgba(232, 227, 24, 1) 100%
  ) !important;
  right: 0;

  @media (max-width: 767px) {
    right: 10vw;
  }

  &:focus {
    background: #342e35 !important;
    color: #e8e318 !important;
  }
`;

interface Props {
  open: boolean;
  onClose?: () => void;
}

export const NewsletterModal: React.FC<Props> = ({ open, onClose }) => {
  const headerRef = usePopupRef<HTMLHeadingElement>(open);

  useEffect(() => {
    if (open) {
      ReactGA.event({
        category: 'Newsletter Signup',
        action: 'Dialog Appeared',
      });
    }
  }, [open]);

  const handleClose = (reason: string) => {
    ReactGA.event({
      category: 'Newsletter Signup',
      action: 'Closed dialog',
      label: reason,
    });
    onClose && onClose();
  };

  return (
    <NewsletterBack
      size="large"
      dimmer="blurring"
      open={open}
      onClose={() => handleClose('Close event')}
      style={{
        fontSize: '1.25em',
        fontFamily: 'Poppins, sans-serif',
        height: '600px',
        width: '600px',
        borderRadius: '32em',
      }}
      role="dialog"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            marginTop: '3em',
            textAlign: 'center',
            height: '60px',
          }}
        >
          <h2
            ref={headerRef}
            tabIndex={-1}
            style={{
              color: '#0032b0',
              fontFamily: 'Poppins Bold',
              fontSize: '36px',
              outline: 'none',
            }}
          >
            OH, HEY!
          </h2>
        </div>
        <div
          style={{
            width: '80%',
            margin: '1em auto auto auto',
            textAlign: 'center',
            fontSize: '20px',
          }}
        >
          <p>We're TimePlay, and we send fun emails.</p>
          <p
            style={{
              color: '#0032B0',
            }}
          >
            Sign up below to subscribe to TimePlay updates like exclusive
            invites and offers.
          </p>
        </div>
        <NewsletterSignup onClose={onClose} />
        <CloseButton
          style={{
            position: 'absolute',
            margin: 'auto',
            padding: '0.5em 0.7em',
            textAlign: 'center',
            color: '#0032B0',
            fontWeight: 'bold',
            fontSize: '1.6em',
            borderRadius: '16em',
          }}
          onClick={() => handleClose('User clicked close button')}
        >
          X
        </CloseButton>
      </div>
    </NewsletterBack>
  );
};
