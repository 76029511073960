import styled from '@emotion/styled';
import React from 'react';
import { Modal, Ref } from 'semantic-ui-react';
import { Button } from '../component/Button';
import { usePopupRef } from '../hooks/usePopupRef';

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const DialogModalBack = styled(Modal)`
  /* Center the dialog popup on landscape devices */
  @media (orientation: landscape) and (max-width: 1023px) and (max-height: 459px),
    /* Also center dialog popup on 1:1 aspect ratio devices such as the BlackBerry Android devices,
    max-height is set to 460 instead of 459 in this case because 460x460 specifically is not centered otherwise */ (aspect-ratio: 1/1) and (max-height: 460px) {
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface Props {
  open: boolean;
  title: string;
  message: JSX.Element;
  onClose?: () => void;
}

export const DialogModal: React.FC<Props> = ({
  open,
  title,
  message,
  onClose,
}) => {
  const headerRef = usePopupRef(open);

  return (
    <DialogModalBack
      size="large"
      open={open}
      onClose={onClose}
      style={{
        fontSize: '1.25em',
        fontFamily: 'Poppins, sans-serif',
        width: 'auto',
      }}
      role="dialog"
    >
      <Ref innerRef={headerRef}>
        <Modal.Header
          tabIndex={-1}
          style={{
            fontFamily: 'Poppins, sans-serif', // TODO define Poppins font site-wide (may need to customize semantic css)
            borderBottom: 'none',
            textAlign: 'center',
            outline: 'none',
          }}
        >
          {title}
        </Modal.Header>
      </Ref>
      <Modal.Content>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          {message}
        </p>
      </Modal.Content>
      <ModalActions>
        <Button
          backgroundColor="#0032B0"
          textColor="#fff"
          hoverBackgroundColor="#E8E318"
          hoverTextColor="#000"
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            boxShadow: '0 3px 6px rgba(0,0,0,0.25)',
            borderRadius: '0.5rem',
          }}
          onClick={onClose}
        >
          OK
        </Button>
      </ModalActions>
    </DialogModalBack>
  );
};
