import { useEffect } from 'react';
import ReactGA from 'react-ga';

const usePageTracking = () => {
  const location = window.location;

  useEffect(() => {
    ReactGA.initialize(
      process.env.REACT_APP_GA_TRACKING_ID || 'UA-188466283-1',
      {
        debug: process.env.NODE_ENV !== 'production',
        gaOptions: {
          cookieFlags: 'SameSite=Lax',
        },
      },
    );
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;
