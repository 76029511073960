import { createContext, useState } from 'react';

type GlobalContextType = {
  hasNewsletterBeenDisplayed: boolean;
  newsletterDisplayed: boolean;
  onNewsletterDisplayed: () => void;
  onNewsletterHidden: () => void;
};

const GlobalContext = createContext<GlobalContextType>({
  hasNewsletterBeenDisplayed: false,
  newsletterDisplayed: false,
  onNewsletterDisplayed: () => {},
  onNewsletterHidden: () => {},
});

export default GlobalContext;

export const GlobalContextProvider: React.FC<{}> = ({ children }) => {
  const [newsletterDisplayed, setNewsletterDisplayed] = useState(false);
  const [hasNewsletterBeenDisplayed, setHasNewsletterBeenDisplayed] = useState(
    false,
  );

  const handleNewsletterDisplayed = () => {
    setNewsletterDisplayed(true);
    setHasNewsletterBeenDisplayed(true);
  };

  const handleNewsletterHidden = () => {
    setNewsletterDisplayed(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        hasNewsletterBeenDisplayed,
        newsletterDisplayed,
        onNewsletterDisplayed: handleNewsletterDisplayed,
        onNewsletterHidden: handleNewsletterHidden,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
