import styled from '@emotion/styled';

type ButtonProps = {
  backgroundColor: string;
  hoverBackgroundColor: string;
  textColor: string;
  hoverTextColor: string;
};

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: 1em 2em;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  border-radius: 0.4em;
  cursor: pointer;
  border: none;

  &:hover,
  &:focus {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverTextColor};
  }
`;
