import React from 'react';
import { Container, Header, List, Ref } from 'semantic-ui-react';
import { usePopupRef } from '../hooks/usePopupRef';
import { PopOpenLink } from './PopOpenLink';

const FAQ: React.FC = () => {
  const headerRef = usePopupRef(true);

  return (
    <Container
      style={{ marginTop: '3rem', paddingBottom: '5rem', marginBottom: '3rem' }}
      text={true}
      role="dialog"
    >
      <Ref innerRef={headerRef}>
        <Header
          tabIndex={-1}
          style={{
            outline: 'none',
          }}
        >
          What is next for TimePlay?
        </Header>
      </Ref>
      <p>
        Our interactive trivia experience TimePlay Live has now come to an end,
        but don’t worry, we’re not going anywhere. We’ve switched gears to level
        up the experience and bring you even more exciting stuff! We can’t wait
        to share what’s coming up next. Stay tuned for special announcements and
        make sure your <b>app notifications</b> are turned on. We’ll be
        continuing to share <b>trivia content and company updates</b> on our
        social platforms, so follow us if you’re not already. Thank you for your
        continued support, loyalty and enthusiasm.
      </p>

      <Header>How do I get the TimePlay App?</Header>
      <List>
        <List.Item>
          <List.Icon name={'app store ios'} />
          <List.Content>
            iOS —{' '}
            <PopOpenLink
              href={'https://apps.apple.com/ca/app/timeplay/id462974334'}
            >
              download here
            </PopOpenLink>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name={'google play'} />
          <List.Content>
            Android —{' '}
            <PopOpenLink
              href={
                'https://play.google.com/store/apps/details?id=com.timeplay&hl=en_CA'
              }
            >
              download here
            </PopOpenLink>
          </List.Content>
        </List.Item>
      </List>

      <Header>How do I fix an issue where my phone is not working?</Header>
      <List bulleted>
        <List.Item>
          Make sure you are on the latest version of the app.
        </List.Item>
        <List.Item>Restart the app.</List.Item>
        <List.Item>Switch your phone network from Wi-Fi to cellular.</List.Item>
      </List>

      <Header>
        Follow TimePlay on social to stay in touch and get the latest updates!
      </Header>
      <List>
        <List.Item>
          <List.Icon name={'facebook'} />
          <List.Content>
            Facebook—
            <PopOpenLink href={'https://www.facebook.com/TimeplayEnt/'}>
              https://www.facebook.com/TimeplayEnt/
            </PopOpenLink>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name={'twitter'} />
          <List.Content>
            Twitter—
            <PopOpenLink href={'https://twitter.com/timeplayent?lang=en'}>
              https://twitter.com/timeplayent?lang=en
            </PopOpenLink>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name={'instagram'} />
          <List.Content>
            Instagram—
            <PopOpenLink href={'https://www.instagram.com/timeplay/?hl=en'}>
              https://www.instagram.com/timeplay/?hl=en
            </PopOpenLink>
          </List.Content>
        </List.Item>
      </List>

      <Header>How do I turn TimePlay’s phone notifications on?</Header>
      <p>iOS users, follow these steps:</p>
      <List ordered>
        <List.Item>Go to the home screen.</List.Item>
        <List.Item>
          Open the <em>Settings</em> app.
        </List.Item>
        <List.Item>
          Scroll down and click on <em>Notifications</em>.
        </List.Item>
        <List.Item>
          Scroll down the list until you see <em>TimePlay</em>.
        </List.Item>
        <List.Item>
          Click the button that appears beside <em>Allow Notifications</em> and
          make sure it's green!
        </List.Item>
      </List>
      <p>Android users, follow these steps:</p>
      <List ordered>
        <List.Item>Go to the home screen.</List.Item>
        <List.Item>
          Open the <em>Settings</em> app.
        </List.Item>
        <List.Item>
          Tap <em>Apps & Notifications</em>.
        </List.Item>
        <List.Item>
          Under <em>Recently opened apps</em>, tap <em>See All</em> at the
          bottom, then find the <em>TimePlay</em> app.
        </List.Item>
        <List.Item>
          Tap the TimePlay app and activate notifications to get show reminders!
        </List.Item>
      </List>

      <Header>I still have questions!</Header>
      <p>
        Please contact TimePlay Support at{' '}
        <PopOpenLink
          href={`mailto:support@timeplay.com?subject=${encodeURI(
            'I still have questions!',
          )}`}
        >
          support@timeplay.com
        </PopOpenLink>
      </p>
    </Container>
  );
};

export default FAQ;
