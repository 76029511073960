import { RefObject, useEffect, useRef } from 'react';

export function usePopupRef<T extends HTMLElement>(
  open: boolean,
): RefObject<T> {
  const headerRef = useRef<T>(null);

  useEffect(() => {
    let prevActiveElement: HTMLElement | null = null;

    if (open) {
      if (document.activeElement instanceof HTMLElement) {
        prevActiveElement = document.activeElement;
      }
      headerRef.current?.focus();
    }

    return () => {
      prevActiveElement?.focus();
    };
  }, [open]);

  return headerRef;
}
