import { BrowserHistory, createBrowserHistory } from 'history';
import { useContext, useEffect, useState } from 'react';
import SmoothScrollContext from '../context/SmoothScrollContext';
import ReactGA from 'react-ga';

const usePageState = (
  pageName: string,
  scrollToTop?: boolean,
): [boolean, () => void, () => void] => {
  const [viewingPage, setViewingPage] = useState(false);
  const [history, setHistory] = useState<BrowserHistory | null>(null);
  const smoothScrollContext = useContext(SmoothScrollContext);
  if (!smoothScrollContext) {
    console.warn('Smooth scroll context is not defined');
  }

  useEffect(() => {
    setHistory(createBrowserHistory());
    if (window.location.pathname === `/${pageName}`) {
      setViewingPage(true);
    }
  }, []);

  const openPage = () => {
    setViewingPage(true);
    history?.push(`/${pageName}`);
    ReactGA.pageview(`/${pageName}`);
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
    smoothScrollContext?.setSmoothScrollEnabled(false);
  };

  const closePage = () => {
    setViewingPage(false);
    history?.push('/');
    smoothScrollContext?.setSmoothScrollEnabled(true);
  };

  return [viewingPage, openPage, closePage];
};

export default usePageState;
